<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Diego Alexander Villalba Cely                                 ###### -->
<!-- ###### @date: Mayo 2023                                                       ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <v-dialog v-model="trazabilidad.visible" max-width="800" persistent>
    <v-card>
      <v-toolbar color="primary" class="text-h6" dark>{{ `Trazabilidad de: ${trazaDe}` }}</v-toolbar>
      <v-card-text>
        <!-- tabla donde muestra la trazabilidad de un registro -->
        <v-data-table class="tablaTrazabilidad mt-3" :headers="tableHeaders" :footer-props="footerProps"
          :items="trazabilidadData" fixed-header>
          <template v-slot:footer.page-text="items">
            {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn text color="red" @click="trazabilidad.visible = false">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
 
 <!-- #################################################################################### -->
 <!-- ###### Sección de Scripts                                                     ###### -->
 <!-- #################################################################################### -->
<script>
import { mapState } from 'vuex';

export default {
  name: "ModalTrazabilidad",
  props: {
    trazabilidadData: Array,
    trazaDe: String,
  },
  data: () => ({
    tableHeaders: [
      { text: "CAMPO", value: "campo", sortable: false },
      { text: "VALOR ANTERIOR", value: "anterior", sortable: false, },
      { text: "VALOR ACTUAL", value: "nuevo", sortable: false, },
      { text: "FECHA DE MODIFICACIÓN", value: "time", sortable: false, },
      { text: "USUARIO", value: "username", sortable: false, },
    ],
    footerProps: {
      "items-per-page-text": "Ítems por página",
      "items-per-page-options": [5, 10, 20],
      "show-current-page": true,
    },
  }),

  computed: {
    ...mapState(["trazabilidad"])
  }
}
</script>
 
 <!-- #################################################################################### -->
 <!-- ###### Sección de Estilos                                                     ###### -->
 <!-- #################################################################################### -->
<style scoped>
::v-deep .tablaTrazabilidad div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .tablaTrazabilidad div table thead tr th span {
  font-weight: bold;
  color: black !important;
}
</style>